//Mobile Menu functions like zoom and hide / show
/*
function menuMobile() {
  if ($("#mobile-display").hasClass("active")) {
    document.getElementById("mobile-display").style.transform = "none";
    document.getElementById("mobile-display").style.height = "auto";
    document.getElementById("mobile-display").style.overflow = "hidden";
    document.getElementById("mobile-display").style.position = "initial";
    document.getElementById("mobile-display").style.top = "0";
    document.getElementById("mobile-display").style.left = "0";
    document.getElementById("mobile-display").style.width = "100%";
    $("#mobile-display").removeClass("active");
  } 
  else {
    document.getElementById("mobile-display").style.transform = "scale(0.75, 0.75)";
    document.getElementById("mobile-display").style.height = "115vh";
    document.getElementById("mobile-display").style.overflow = "hidden";
    document.getElementById("mobile-display").style.position = "absolute";
    document.getElementById("mobile-display").style.top = "-9%";
    document.getElementById("mobile-display").style.left = "45%";
    document.getElementById("mobile-display").style.width = "320px";
    $("#mobile-display").addClass("active");
    $("html, body").animate({ scrollTop: 0 }, "fast");
  }
}
*/
/*
$('#mobile-display').on('click', function(){
  
  else{
    return;
  }
});
*/

$('.menu-link').on('click', function(){
  if (!$('#mobile-display').hasClass('active')){
    $('#mobile-display').css({
      'transform':'scale(0.75, 0.75)',
      'height':'115vh',
      'overflow':'hidden',
      'position':'absolute',
      'top':'-9%',
      'left':'45%',
      'width':'320px'
    });
    $("#mobile-display").addClass("active");
    $("html, body").animate({ scrollTop: 0 }, "fast");
  }
  else if($('#mobile-display').hasClass('active')){
    $('#mobile-display').css({
      'transform': 'none',
      'height': 'auto',
      'overflow': 'hidden',
      'position':'initial',
      'top':'0',
      'left': '0',
      'width':'100%'
    });
    $('#mobile-display').removeClass('active');
  }
});



// Slick load

$(document).ready(function() {
  $(".selections").slick({
    infinite: true,
    slidesToScroll: 11,
    slidesToShow: 11,
    autoplay: true,
    arrows: false,
    variableWidth: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 1980,
          settings:{
            slidesToScroll:6,
            slidesToShow: 6
          }        
      },
      {
        breakpoint: 1380,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2,
          infinite: true,
          variableWidth: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2,
          variableWidth: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          autoplay: true
        }
      }
    ]
  });
  $(".services-panels-home").slick({
    autoplay: false,
    dots: false,
    centermode: true,
    infinite: true,
    mobileFirst: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: 'unslick'
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true
        }
      }
    ]
  });
  $(".services-panels-about").slick({
    autoplay: false,
    dots: false,
    infinite: true,
    variableWidth: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [      
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  });
  $(".quotes").slick({
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    variableWidth: false    
  });
  $(".team-content").slick({
    autoplay: false,
    dots: false,
    arrows: false,
    infinite: false,
    variableWidth: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 860,
        settings: 'unslick'
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]    
  });
  $(".product-boxes").slick({
    autoplay: false,
    dots: false,
    infinite: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: 'unslick'
      },
      {
        breakpoint: 960,
        settings:{
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 640,
        settings:{
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 320,
        settings:{
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          variableWidth: false,
          infinite: false
        }
      }
    ]
  });
});


// Cookie notice

$(document).ready(function() {
  if ($.cookie("noCookie")) {
    $("#cookieConsent").hide();
  } else {
    setTimeout(function() {
      $("#cookieConsent").fadeIn(200);
    }, 4000);
  }
  $("#closeCookieConsent, .cookieConsentOK").click(function() {
    $("#cookieConsent").fadeOut(200);
    $.cookie("noCookie", true);
  });
});

$('#dots').on('click', function(){
  $('#more-text').css('display', 'block');
  $('#dots').addClass('invisible-dots');
})
$(document).ready(function(){
  $(".service-panel").on('click', function(){
      window.location.href = "/about#services-about";
  });
});