
// Shop information
const client = ShopifyBuy.buildClient({
    domain: "maxx-weine.myshopify.com",
    storefrontAccessToken: "161aefff9f4b3026a5a7a1c2bb6399d0"
  });
  // Featured Collection ID
  const collectionId = "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjQwNjM4MjY4Mg==";
 

  client.collection.fetchWithProducts(collectionId).then(collection => {
    
    let $featuredProducts = $(".product-box");
    for (let i = 0; i < collection.products.length; i++) {
      let prod = collection.products[i];
  
      $($featuredProducts[i])
        .find("a")
        .attr(
          "href",
          "https://" + client.config.domain + "/products/" + prod.handle
        );
      $($($featuredProducts[i]).find(".model")).text(prod.title);
      $($($featuredProducts[i]).find(".manufacturer")).text(prod.vendor);
      $($($featuredProducts[i]).find(".price")).text(
        prod.variants[0].price
          .toString()
          .replace(",", "_")
          .replace(".", ",")
          .replace("_", ".") + " €"
      );
      if (prod.images && prod.images[0]) {
        let src = prod.images[0].src;
        let extensionIndex = src.lastIndexOf(".");
        let pathAndBasename = src.slice(0, extensionIndex);
        let extension = src.slice(extensionIndex);
        let variantsrc = `${pathAndBasename}_large${extension}`;
        $($featuredProducts[i])
          .find(".img-fluid")
          .attr("src", variantsrc);
        
        $($featuredProducts[i])
          .find(".img-fluid")
          .attr("alt", prod.images[0].altText);
      }
    }
  });


/* 
  // Fetch all products in your shop
  client.product
    .fetchQuery({ first: 4, sortKey: "CREATED_AT", reverse: true })
    .then(products => {
      // Do something with the products
      let $newlyArrived = $(".newly-arrived-box-hover");
      for (let i = 0; i < products.length; i++) {
        let e = products[i];
        let $box = $(".newly-arrived-box > .img-fluid")[i];
        let $box2 = $(".newly-arrived-box > .newly-arrived-box-hover img")[i];
        let $box3 = $(".newly-arrived-box")[i];
        $($($newlyArrived[i]).find(".model")).text(e.title);
        $($($newlyArrived[i]).find(".manufacturer")).text(e.vendor);
        $($($newlyArrived[i]).find(".price")).text(
          e.variants[0].price
            .toString()
            .replace(",", "_")
            .replace(".", ",")
            .replace("_", ".") + " €"
        );
        $($($newlyArrived[i]).children("a")).attr(
          "href",
          "https://" + client.config.domain + "/products/" + e.handle
        );
        if (e.images && e.images[0]) {
          let src = e.images[0].src;
          let extensionIndex = src.lastIndexOf(".");
          let pathAndBasename = src.slice(0, extensionIndex);
          let extension = src.slice(extensionIndex);
          let variantsrc = `${pathAndBasename}_large${extension}`;
          $($box).attr("src", variantsrc);
          $($box).attr("alt", e.images[0].altText);
        }
      }
    });

  */